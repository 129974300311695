.item-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.item-wrapper .item {
  display: flex;
  cursor: pointer;
  position: relative;
}
.item-wrapper .item .icon {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 18px;
}
.item-wrapper .item .icon.new {
  background: url("~@/assets/images/img1.png");
  background-size: 100% 100%;
}
.item-wrapper .item .icon.update {
  background: url("~@/assets/images/img2.png");
  background-size: 100% 100%;
}
.item-wrapper .item.opacity {
  opacity: 0.5;
}
.item-wrapper.icon-size-1 .item,
.item-wrapper.icon-size-4 .item,
.item-wrapper.icon-size-5 .item {
  height: 30px;
  overflow: hidden;
  background: #f2f2f2;
  border-radius: 2px;
  display: flex;
  margin-right: 12px;
  margin-bottom: 8px;
  align-items: center;
  transition: 0.3s;
  border: 1px solid #dfdfdf;
  transition: all 0.3s;
  -webkit-font-smoothing: antialiased;
}
.item-wrapper.icon-size-1 .item:hover,
.item-wrapper.icon-size-4 .item:hover,
.item-wrapper.icon-size-5 .item:hover {
  transform: translateY(-2px) translateZ(0) scale(1.1);
}
.item-wrapper.icon-size-1 .item img,
.item-wrapper.icon-size-4 .item img,
.item-wrapper.icon-size-5 .item img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-left: 4px;
  object-fit: cover;
}
.item-wrapper.icon-size-1 .item span,
.item-wrapper.icon-size-4 .item span,
.item-wrapper.icon-size-5 .item span {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  padding: 0 4px 0 8px;
  color: #424242;
  font-size: 14px;
  text-align: center;
}
.item-wrapper.icon-size-1 .item img + span,
.item-wrapper.icon-size-4 .item img + span,
.item-wrapper.icon-size-5 .item img + span {
  width: clac(74%);
  text-align: left;
}
.item-wrapper.icon-size-2 .item,
.item-wrapper.icon-size-3 .item {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background: #f8f8f8;
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  transition: all 0.3s;
  -webkit-font-smoothing: antialiased;
}
.item-wrapper.icon-size-2 .item:hover,
.item-wrapper.icon-size-3 .item:hover {
  transform: translateY(-2px) translateZ(0) scale(1.1);
}
.item-wrapper.icon-size-2 .item img,
.item-wrapper.icon-size-3 .item img {
  width: 100%;
  height: 70px;
  max-width: 100%;
  object-fit: contain;
}
.item-wrapper.icon-size-2 .item span,
.item-wrapper.icon-size-3 .item span {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 20px;
  height: 20px;
  color: #424242;
  width: 100%;
  font-size: 12px;
  text-align: center;
}
.item-wrapper.icon-size-6 .item,
.item-wrapper.icon-size-7 .item {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background: #f8f8f8;
  border: 1px solid #e5e5e5;
  width: calc((100% - 8px * 7) / 8);
  margin-right: 8px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  transition: all 0.3s;
  -webkit-font-smoothing: antialiased;
}
.item-wrapper.icon-size-6 .item:hover,
.item-wrapper.icon-size-7 .item:hover {
  transform: translateY(-2px) translateZ(0) scale(1.1);
}
.item-wrapper.icon-size-6 .item:nth-child(8n),
.item-wrapper.icon-size-7 .item:nth-child(8n) {
  margin-right: 0;
}
.item-wrapper.icon-size-6 .item img,
.item-wrapper.icon-size-7 .item img {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}
.item-wrapper.icon-size-6 .item span,
.item-wrapper.icon-size-7 .item span {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 20px;
  height: 20px;
  color: #424242;
  width: 100%;
  font-size: 12px;
  text-align: center;
}
.item-group.icon-size-1 .item,
.item-group.icon-size-4 .item,
.item-group.icon-size-5 .item {
  width: calc((100% - 12px * 4) / 5);
}
.item-group.icon-size-1 .item:nth-child(5n),
.item-group.icon-size-4 .item:nth-child(5n),
.item-group.icon-size-5 .item:nth-child(5n) {
  margin-right: 0;
}
.item-group.icon-size-2 .item {
  width: calc((100% - 10px * 7.5) / 11);
  margin-right: 7px;
}
.item-group.icon-size-2 .item:nth-child(11n) {
  margin-right: 0;
}
.item-group.icon-size-3 .item {
  width: calc((100% - 13px * 5) / 6);
  margin-right: 13px;
}
.item-group.icon-size-3 .item:nth-child(6n) {
  margin-right: 0;
}
.item-group.icon-size-6 .item img {
  height: 90px;
}
.item-group.icon-size-7 .item img {
  height: 117px;
}
.nav-sort-box .filter-item {
  min-height: 28px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.nav-sort-box .filter-item + .filter-item {
  margin-left: 0;
}
.filter-option-item {
  display: flex;
  align-items: center;
}
.filter-option-item img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.ba-item-group.icon-size-3 .item {
  height: auto;
  background: #f7feff;
  box-shadow: 0px 2px 4px 0px rgba(85, 85, 85, 0.5);
  border-radius: 4px;
  padding: 3px 3px 0 3px;
}
.ba-item-group.icon-size-3 .item img {
  width: 100%;
  height: 67px;
  border-radius: 4px;
}
.ba-item-group.icon-size-3 .item span {
  line-height: 26px;
  height: 26px;
  color: #2c343f;
}
.ba-item-group.icon-size-6 .item {
  background: url("~@/assets/ba_imgs/item-bg-icon.png") no-repeat center / 100% 100%;
  height: 140px;
  padding: 8px 12px 14px 12px;
  border: none;
  border-radius: 2px;
}
.ba-item-group.icon-size-6 .item img {
  border-radius: 4px;
  border: 2px solid #fff;
  box-shadow: 0 1px 1px #999;
  height: 85px;
  background: linear-gradient(180deg, #dadee6 0%, #ffffff 100%);
}
.ba-item-group.icon-size-6 .item span {
  background: #565d7a;
  color: #fff;
  font-size: 12px;
  margin-top: 8px;
  border-radius: 4px;
  padding: 0 4px;
  text-align: center;
}
.ba-item-group.icon-size-2 .item {
  background: url("~@/assets/ba_imgs/item-bg-icon.png") no-repeat center / 100% 100%;
  height: 105px;
  padding: 5px 6px 10px 6px;
  border: none;
  border-radius: 2px;
  justify-content: space-between;
}
.ba-item-group.icon-size-2 .item img {
  border-radius: 4px;
  border: 2px solid #fff;
  box-shadow: 0 1px 1px #999;
  height: 60px;
  background: linear-gradient(180deg, #dadee6 0%, #ffffff 100%);
}
.ba-item-group.icon-size-2 .item span {
  background: #565d7a;
  color: #fff;
  font-size: 12px;
  margin-top: 8px;
  border-radius: 4px;
  padding: 0 4px;
  text-align: center;
}
.ba-item-group.icon-size-1 .item {
  background: url("~@/assets/ba_imgs/item-bg-bar.png") no-repeat center / 100% 100%;
  border: none;
  font-size: 13px;
  color: #424242;
  padding: 0 8px;
}
.zsca2-nav-sort-box /deep/ .el-input input {
  border: 1px solid rgba(216, 216, 216, 0.16);
  background: rgba(216, 216, 216, 0.16);
}
.zsca2-nav-sort-box /deep/ .el-button {
  border: 1px solid rgba(216, 216, 216, 0.16);
  background: rgba(216, 216, 216, 0.16);
}
.zsca2-item-group.icon-size-1 .item,
.zsca2-item-group.icon-size-4 .item,
.zsca2-item-group.icon-size-5 .item {
  height: 28px;
  border: 1px solid transparent;
  background: var(--zs-model-wrapper-item-bg);
  border-radius: 4px;
}
.zsca2-item-group.icon-size-1 .item span,
.zsca2-item-group.icon-size-4 .item span,
.zsca2-item-group.icon-size-5 .item span {
  color: var(--zs-model-wrapper-item-name-color);
}
.zsca2-item-group.icon-size-2 .item,
.zsca2-item-group.icon-size-3 .item {
  border: 1px solid transparent;
  background: var(--zs-model-wrapper-item-bg);
  border-radius: 8px;
}
.zsca2-item-group.icon-size-2 .item span,
.zsca2-item-group.icon-size-3 .item span {
  color: var(--zs-model-wrapper-item-name-color);
}
.zsca2-item-group.icon-size-6 .item {
  border: 1px solid transparent;
  background: var(--zs-model-wrapper-item-bg);
  border-radius: 8px;
}
.zsca2-item-group.icon-size-6 .item span {
  color: var(--zs-model-wrapper-item-name-color);
}
.zsca2-item-group.icon-size-6 .item img {
  height: 92px;
}
.zsca2-item-group.icon-size-7 .item {
  border: 1px solid transparent;
  background: var(--zs-model-wrapper-item-bg);
  border-radius: 8px;
}
.zsca2-item-group.icon-size-7 .item span {
  color: var(--zs-model-wrapper-item-name-color);
}
.zsca2-item-group.icon-size-7 .item img {
  height: 120px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
